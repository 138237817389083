const ActivityLogLevel = ({ activityLog }) => {
  return activityLog.level === 1 ? (
    <span className="inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
      Low
    </span>
  ) : activityLog.level === 2 ? (
    <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
      Medium
    </span>
  ) : (
    <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
      High
    </span>
  );
};

export default ActivityLogLevel;
