import { FilterGroup } from 'components/Filters';
import RangePicker from 'components/RangePicker';
import { levels } from 'features/roles/constants';
import { useUsers } from 'features/users';
import moment from 'moment';
import { useEffect, useState } from 'react';

const Filters = ({ filters, setFilters }) => {
  const { fetchAllUsers, users } = useUsers();

  const [userSearch, setUserSearch] = useState('');
  const [triggeredByIds, setTriggeredByIds] = useState([]);
  const [levels, setLevels] = useState([]);
  const [actions, setActions] = useState([]);
  const [createdAt, setCreatedAt] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let filters = [
        {
          attribute: 'role.level',
          operator: 'EQ',
          value: 'agency',
        },
      ];

      await fetchAllUsers({
        include: ['avatar'],
        search: userSearch,
        sorts: 'firstName:ASC',
        filterGroups: JSON.stringify([{ filters }]),
        attributes: ['userId', 'roleId', 'firstName', 'lastName', 'email'],
      });
    };

    fetchData().catch(console.error);
  }, [userSearch]);

  const onApplyFilter = () => {
    let newFilters = [];

    if (triggeredByIds.length) {
      newFilters.push({
        operator: 'IN',
        value: triggeredByIds,
        attribute: 'triggeredById',
      });
    }

    if (levels.length) {
      newFilters.push({
        operator: 'IN',
        value: levels,
        attribute: 'level',
      });
    }

    if (actions.length) {
      newFilters.push({
        operator: 'IN',
        value: actions,
        attribute: 'action',
      });
    }

    if (createdAt.length) {
      newFilters.push({
        operator: 'BETWEEN',
        value: createdAt,
        attribute: 'createdAt',
      });
    }

    setFilters(newFilters);
  };

  const DateFilterComponent = ({ values = [], onChange }) => {
    const [selectedDates, setSelectedDates] = useState(values);

    return (
      <RangePicker
        monthsToDisplay={1}
        orientation="vertical"
        selectedDates={selectedDates.map((value) => new Date(value))}
        setSelectedDates={(dates) => {
          const formattedDates = dates.map((date) =>
            moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD')
          );

          setSelectedDates(formattedDates);

          if (dates.length === 2) {
            onChange(formattedDates);
          }
        }}
        ignoreMinDate={true}
      />
    );
  };

  return (
    <FilterGroup
      onApply={onApplyFilter}
      onClear={() => {
        setLevels([]);
        setTriggeredByIds([]);
        setCreatedAt([]);
      }}
      filters={[
        {
          field: 'level',
          title: 'Level',
          values: levels,
          options: [
            { value: '1', label: 'Low' },
            { value: '2', label: 'Medium' },
            { value: '3', label: 'High' },
          ],
          onChange: (values) => {
            setLevels(values.level);
          },
        },
        {
          field: 'action',
          title: 'Action',
          values: actions,
          options: [
            { value: 'INFORMATION', label: 'Information' },
            { value: 'CREATE', label: 'Create' },
            { value: 'UPDATE', label: 'Update' },
            { value: 'DELETE', label: 'Delete' },
          ],
          onChange: (values) => {
            setActions(values.action);
          },
        },
        {
          title: 'User',
          field: 'userId',
          searchable: true,
          onSearch: (search) => setUserSearch(search),
          values: triggeredByIds,
          options: users.data.map((user) => {
            return {
              ...user,
              value: user.userId,
              label: `${user.firstName} ${user.lastName}`,
            };
          }),
          onChange: (values) => {
            setTriggeredByIds(values.userId);
          },
        },
        {
          field: 'createdAt',
          title: 'Triggered At',
          values: createdAt,
          FilterComponent: DateFilterComponent,
          onChange: (values) => {
            setCreatedAt(values);
          },
        },
      ]}
    />
  );
};

export default Filters;

// triggeredById: Joi.string().guid(),
