const ActivityLogAction = ({ activityLog }) => {
  return activityLog.action === 'INFORMATION' ? (
    <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700">
      Information
    </span>
  ) : activityLog.action === 'CREATE' ? (
    <span className="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800">
      Create
    </span>
  ) : activityLog.action === 'UPDATE' ? (
    <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
      Update
    </span>
  ) : (
    <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
      Delete
    </span>
  );
};

export default ActivityLogAction;
