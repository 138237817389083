import { XIcon } from '@heroicons/react/outline';

import { Button, Modal, UserDisplay } from 'components';
import moment from 'moment';
import ActivityLogChangeItem from './ActivityLogChangeItem';
import { Field, Form, Formik } from 'formik';
import { RequiredAsterisk, Label } from 'components/Forms';
import useActivityLogs from '../useActivityLogs';
import usePermissions from 'hooks/usePermissions';

const ActivityLogDetailsModal = ({ open, setOpen, activityLog, onUpdate }) => {
  const { updateActivityLog } = useActivityLogs();
  const { userCan } = usePermissions();

  const onSubmit = async (values, helper) => {
    try {
      await updateActivityLog(activityLog.id, values);
      onUpdate();
    } catch (error) {
      console.log(error);
    } finally {
      helper.setSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex justify-between items-center px-8 pt-4 pb-4 border-b">
          <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
            Activity Log Details
          </h4>

          <XIcon
            className="w-5 h-5 text-grayscale-700 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>

        {activityLog && (
          <div className="px-8 space-y-4 my-5">
            <div>
              <UserDisplay bgColor="#1879D8" user={activityLog.triggeredBy} />
            </div>

            <div>
              <p className="font-medium text-gray-800">Description:</p>
              <p className="text-grayscale-800 text-sm tracking-wide">
                {activityLog.description}
              </p>
            </div>

            <div>
              <p className="font-medium text-gray-800">Date & Time:</p>
              <p className="text-grayscale-800 text-sm tracking-wide">
                {moment(activityLog.createdAt)
                  .tz(moment.tz.guess())
                  .format('MMMM Do YYYY, hh:mm:ss a')}
              </p>
            </div>

            <div>
              {activityLog.properties?.changed && (
                <>
                  <div>
                    <p className="font-medium text-gray-800">Changes:</p>
                  </div>

                  <ul className="list-disc mt-2 space-y-3">
                    {activityLog.properties.changed.map((attr) => (
                      <ActivityLogChangeItem
                        key={attr}
                        attribute={attr}
                        values={activityLog.properties.values}
                        previousValues={activityLog.properties.previousValues}
                      />
                    ))}
                  </ul>
                </>
              )}
            </div>

            <div>
              <Formik
                onSubmit={onSubmit}
                initialValues={{
                  level: activityLog.level,
                }}
              >
                {({ isSubmitting, isValid, dirty, resetForm }) => (
                  <Form className="">
                    <div className="space-y-5">
                      <div className="">
                        <Label
                          classes="font-medium"
                          textColor="text-gray-800"
                          textSize="normal"
                        >
                          Level
                          <RequiredAsterisk />
                        </Label>

                        <Field
                          as="select"
                          name="level"
                          disabled={!userCan('admin.activity_logs.update')}
                          className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                        >
                          <option value={1}>Low</option>
                          <option value={2}>Medium</option>
                          <option value={3}>High</option>
                        </Field>
                      </div>

                      <div className="flex justify-between">
                        <button
                          type="button"
                          onClick={() => {
                            resetForm();
                            setOpen(false);
                          }}
                          className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                        >
                          Close
                        </button>

                        {userCan('admin.activity_logs.update') && (
                          <Button
                            type="submit"
                            roundedSize={40}
                            showLoading={true}
                            loading={isSubmitting}
                            disabled={!dirty}
                            classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ActivityLogDetailsModal;
